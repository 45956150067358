import React, { useEffect, useState } from "react";
import AccountPageNavigation from "../../account/AccountPageNavigation";
import Breadcrumbs from "../../common/breadcrumbs/Breadcrumbs";
import Image from "../../common/Image";
import defaultImage from "../../../../static/img/default-bc-product.png";
import useGetBulkOrdersDetails from "../../../hooks/bulk-orders/useGetBulkOrdersDetails";
import { formatDateNew } from "../../../utils/date";
import useGetRepresentativesDetails from "../../../hooks/representatives-details/useGetRepresentativesDetails";
import RepresentativeNavigation from "../../account/RepresentativeNavigation";
import Loader from "../../form/Loader";
import useGetBulkOrdersHistory from "../../../hooks/bulk-orders/useGetOrdersHistory";
import IconMinus from "../../../assets/icon_js/IconMinus";
import IconPlus from "../../../assets/icon_js/IconPlus";
import { formatPrice } from "../../../utils/money";
import Tableheader from "./table/Tableheader";
import TableBody from "./table/TableBody";
import TableFooter from "./table/TableFooter";

const PurchaseOrdersDetails = ({ location, path }) => {
  const params = new URLSearchParams(location.search);
  const po_id = params.get("id");
  const getDetails = useGetBulkOrdersDetails();
  const getHistoryData = useGetBulkOrdersHistory();
  const [orders, setOrders] = useState();
  const [orderHistoryData, setrderHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderHistoryLoading, setOrderHistoryLoading] = useState(false);
  const { details: representative } = useGetRepresentativesDetails();
  const [collapsedTables, setCollapsedTables] = useState([]);

  useEffect(() => {
    setCollapsedTables(Array(orders?.line_items?.length).fill(true));
  }, [orders]);

  useEffect(() => {
    const fetchData = async () => {
      if (po_id) {
        setLoading(true);
        setOrderHistoryLoading(true);
        const { data } = await getDetails(po_id);
        const { data: orderHistoryData } = await getHistoryData(po_id);
        if (data) {
          setOrders(data[0]);
          setLoading(false);
        }
        if (orderHistoryData) {
          setrderHistoryData(orderHistoryData);
          setOrderHistoryLoading(false);
        } else {
          setOrderHistoryLoading(false);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [po_id]);

  const toggleTable = (index) => {
    const newCollapsedTables = [...collapsedTables];
    newCollapsedTables[index] = !newCollapsedTables[index];
    setCollapsedTables(newCollapsedTables);
  };

  return (
    <div className="page-wrapper account-page-wrapper">
      <div className="container">
        <Breadcrumbs location={location} />
      </div>
      <div className="container">
        <div className="account-content">
          <div className="row flex flex-wrap page-layout">
            <div className="col page-sidebar">
              <div className="page-sidebar-inner">
                <AccountPageNavigation path={path} />
                {representative && (
                  <RepresentativeNavigation representative={representative} />
                )}
              </div>
            </div>
            <div className="col page-content">
              <div className="page-content-inner">
                {loading ? (
                  <Loader />
                ) : orders && po_id ? (
                  <>
                    <div className="page-heading-section">
                      <h1 className="page-title">
                        {orders?.status?.toUpperCase()} PO
                      </h1>
                    </div>

                    <div className="card-body bulk-orer-card">
                      <p>
                        <strong>PO:</strong> {orders?.po_id || "-"}
                      </p>
                      <p>
                        <strong>Date: </strong>
                        {formatDateNew(orders?.created_at) || "-"}
                      </p>
                      <p>
                        <strong>Status: </strong>
                        {orders?.status.toUpperCase() || "-"}
                      </p>
                      <p>
                        <strong>Sales Representative:</strong>{" "}
                        {representative?.name || "-"}
                      </p>
                    </div>

                    {orders?.line_items?.map((item, index) => (
                      <div
                        key={index}
                        className="bulk-order-catalogue-section page-block"
                      >
                        <div className="bulk-order-catalogue-product flex row">
                          <div className="catalogue-toggle-button col">
                            <span
                              className="icon"
                              onClick={() => toggleTable(index)}
                            >
                              {collapsedTables[index] ? (
                                <IconMinus />
                              ) : (
                                <IconPlus />
                              )}
                            </span>
                          </div>

                          <div className="catalogue-product-image col">
                            <Image
                              src={item.product_image || defaultImage}
                              alt="ProductThumb"
                            />
                          </div>
                          <div className="catalogue-product-detail col">
                            <h6 className="">
                              <strong>{item.bc_name}</strong>
                            </h6>
                            <p>Case QTY: {item.case_qty}</p>
                            <p>Pack Of: {item.display_qty}</p>
                            <p>
                              Total Pieces:{" "}
                              <strong>{item.display_qty * item.case_qty}</strong>
                            </p>
                          </div>
                        </div>

                        <div
                          className={`bulk-order-catalogue-table-section bulk-order-detail-table-section ${
                            collapsedTables[index] ? "show-table" : "hide-table"
                          }`}
                        >
                          <table className="bulk-order-table bulk-order-detail-table">
                            {/* Table header */}
                            <Tableheader  displayQty={item?.display_qty} />

                            {/* Table Body */}
                            <TableBody item={item} />

                            {/* Table Footer */}
                            <TableFooter item={item} />
                          </table>
                        </div>
                      </div>
                    ))}
                    <h6>
                      <strong>Order History</strong>
                    </h6>
                    <div className="bulk-order-catalogue-table-section">
                      <table className="bulk-order-table bulk-order-history-table">
                        <thead className="">
                          <tr>
                            <th className="w-250px">Date</th>
                            <th className="w-250px">Order Id</th>
                            <th className="w-250px">
                              Order Value
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {orderHistoryData.length > 0
                            ? orderHistoryData.map((rowData, rowIndex) => (
                                <tr key={rowIndex}>
                                  <td>
                                    {formatDateNew(rowData.created_at) || "-"}
                                  </td>
                                  <td>#{rowData.bc_order_id || "-"} </td>
                                  <td>
                                    {formatPrice(rowData.order_total) || "-"}
                                  </td>
                                </tr>
                              ))
                            : !orderHistoryLoading && (
                                <tr>
                                  <td colSpan={3}>
                                    <p className="text-center">
                                      No Record found
                                    </p>
                                  </td>
                                </tr>
                              )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <p>Poid is missing</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrdersDetails;
