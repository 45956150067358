import React from "react";

const Tableheader = ({displayQty}) => {
  return (
    <thead className="">
      <tr>
        <th className="w-125px">{displayQty} Pack UPC</th>
        <th className="w-250px">flavor</th>
        <th className="w-125px text-center">required qty</th>
        <th className="w-125px text-center">approved</th>
        <th className="w-125px text-center">cancelled</th>
        <th className="w-125px text-center">Backordered</th>
      </tr>
    </thead>
  );
};

export default Tableheader;
