import React from "react";

const TableBody = ({ item }) => {
  return (
    <tbody className="">
      {item.variants.map((rowData, rowIndex) => (
        <tr key={rowIndex}>
          <td>{rowData.bc_upc || "-"}</td>
          <td>{rowData.option || rowData?.po_option || "-"}</td>
          <td className="text-center">{rowData.requested_qty || "-"}</td>
          <td className="text-center">{rowData.fullfilled_qty || "-"}</td>
          <td className="text-center">
            {rowData.status === "cancelled" ? rowData.remaining_qty : "-"}
          </td>
          <td className="text-center">
            {rowData.status === "backorder" ? rowData.remaining_qty : "-"}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
