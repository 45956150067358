import Api from "../../services/Api";
import { createPo } from "../../ApiEndpoints";

function useGetBulkOrdersHistory() {
  const getKey = (poId) => {
    return `${createPo}/${poId}/completed`;
  };

  const getHistoryData = async (poId) => {
    try {
      const res = await getBulkOrdersHistory(getKey(poId));
      return { data: res?.data };
    } catch (error) {
      return { data: [] };
    }
  };

  return getHistoryData;
}

async function getBulkOrdersHistory(url) {
  const response = await Api.get(url);
  return response || [];
}

export default useGetBulkOrdersHistory;
