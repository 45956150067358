import React from "react";
import { calculateDetailsPageTotal } from "../../../../utils/bulkOrder";

const TableFooter = ({ item }) => {
  return (
    <tfoot className="">
      <tr className="">
        <td></td>
        <td className="">
          <strong>Total Case Qty</strong>
        </td>
        <td className="text-center">
          <strong>{calculateDetailsPageTotal(item)?.totalRequestedQty}</strong>
        </td>
        <td className="text-center">
          <strong>{calculateDetailsPageTotal(item)?.totalFullfilledQty}</strong>
        </td>
        <td className="text-center">
          <strong>{calculateDetailsPageTotal(item)?.totalCancelledQty}</strong>
        </td>
        <td className="text-center">
          <strong>{calculateDetailsPageTotal(item)?.totalBackOrderd}</strong>
        </td>
      </tr>
    </tfoot>
  );
};

export default TableFooter;
