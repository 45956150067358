import Api from "../../services/Api";
import { createPo } from "../../ApiEndpoints";

function useGetBulkOrdersDetails() {
  const getKey = (poId) => {
    return `${createPo}/${poId}`;
  };

  const getDetails = async (poId) => {
    try {
      const res = await getBulkOrdersDetails(getKey(poId));
      if (res?.data) {
      }
      return { data: res?.data };
    } catch (error) {
      return { data: [] };
    }
  };

  return getDetails;
}

function getBulkOrdersDetails(url) {
  const response = Api.get(url);
  return response || [];
}

export default useGetBulkOrdersDetails;
