import React, { useEffect } from "react";
import Layout from "../../../layout/Layout";
import { staticPage } from "../../../../config/staticPage";
import PurchaseOrdersDetails from "../../../components/account-bulk-orders/purchase-orders/purchase-orders-details";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";

const CompletedOrdersDetails = ({ location }) => {
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate(`/login?redirect_to=${location?.href}`);
    }
  }, []);

  if (!user) {
    return null;
  } else {
    return (
      <PurchaseOrdersDetails
        location={location}
        path={"/purchase-orders/completed"}
      />
    );
  }
};

export const Head = () => {
  const login_nav = staticPage.find(
    (obj) => obj.name === "completed-orders-details"
  );
  return (
    <>
      <title>{login_nav?.title}</title>
      <meta name="description" content={login_nav?.description} />
    </>
  );
};

CompletedOrdersDetails.Layout = Layout;

export default CompletedOrdersDetails;
